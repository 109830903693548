type FormCryptoQrParams = {
    network: string;
    address: string;
    amount?: number | string;
};

const formCryptoQr = (params: FormCryptoQrParams) => {
    const {
        network,
        address,
        amount,
    } = params;

    switch (network.toLowerCase()) {
        case 'ethereum': {
            return `ethereum:${address}${amount ? `?amount=${amount}` : ''}`;
        }
        case 'bitcoin': {
            return `bitcoin:${address}${amount ? `?amount=${amount}` : ''}`;
        }
        case 'bnb smart chain': {
            return `ethereum:${address}${amount ? `?amount=${amount}` : ''}`;
        }
        case 'bnb beacon chain': {
            return `bnb:${address}${amount ? `?amount=${amount}` : ''}`;
        }
        case 'cardano': {
            return `cardano:${address}${amount ? `?amount=${amount}` : ''}`;
        }
        case 'dash': {
            return `dash:${address}${amount ? `?amount=${amount}` : ''}`;
        }
        case 'eos': {
            return `eos:${address}${amount ? `?amount=${amount}` : ''}`;
        }
        case 'ethereum classic': {
            return `ethereumclassic:${address}${amount ? `?amount=${amount}` : ''}`;
        }
        case 'litecoin': {
            return `litecoin:${address}${amount ? `?amount=${amount}` : ''}`;
        }
        case 'polygon': {
            return `ethereum:${address}${amount ? `?amount=${amount}` : ''}`;
        }
        case 'ripple': {
            return `ripple:${address}${amount ? `?amount=${amount}` : ''}`;
        }
        case 'stellar': {
            return `stellar:${address}${amount ? `?amount=${amount}` : ''}`;
        }
        case 'tezos': {
            return `tezos:${address}${amount ? `?amount=${amount}` : ''}`;
        }
        case 'tron': {
            return `tron:${address}${amount ? `?amount=${amount}` : ''}`;
        }
        case 'zcash': {
            return `zcash:${address}${amount ? `?amount=${amount}` : ''}`;
        }
        default: {
            return '';
        }
    }
};

export {
    formCryptoQr,
    FormCryptoQrParams,
};
