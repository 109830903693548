
import Vue from 'vue';
import { mapGetters } from 'vuex';

import TextInput from 'Control/TextInput.vue';
import Button from 'Control/Button.vue';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import AssetsBlockchainsDropdown from 'Control/AssetsBlockchainsDropdown.vue';
import MoneyInput from 'Control/MoneyInput.vue';
import AssetValue from 'UI/AssetValue.vue';
import Checkbox from 'Control/Checkbox.vue';
import WalletsApi from 'Apis/Wallets';
import WalletAddressRequestData from 'Entities/walletExecutor/WalletAddressRequestData';
import WalletAddressResponseData from 'Entities/walletExecutor/WalletAddressResponseData';
import ApiError from 'Entities/ApiError';
import CreateDepositRequestData from 'Entities/walletExecutor/CreateDepositRequestData';
import TransferRequest from 'Entities/privatePresenter/TransferRequest';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import Icon from 'UI/Icon.vue';
import { copyTextWithPermissions } from 'Lib/utils/copyTextWithPermissions';
import QrCode from 'UI/QrCode.vue';
import CancelDepositRequestData from 'Entities/walletExecutor/CancelDepositRequestData';
import { formCryptoQr } from 'Lib/utils/formCryptoQr';

type Data = {
    step: number;
    previousRoute: string;
    showAmountTooltip: boolean;
    showWhitelistingDetails: boolean;
    showQr: boolean;
    formData: {
        name: string;
        networkIndex: number;
        address: string;
        amount: number;
        isUserAgree: boolean;
    };
    errors: {
        name: boolean;
        address: boolean;
        amount: boolean;
        isUserAgree: boolean;
    };
    depositData: {
        address: string;
        memo: string;
        id: string;
        totalAmount: number;
    };
};

type Methods = {
    goBack: () => void;
    cancel: () => void;
    nextStep: () => void;
    presetBlockchain: (data: string) => void;
    setName: (data: string) => void;
    setNetworkIndex: (data: number) => void;
    setAddress: (data: string) => void;
    setAmount: (data: number) => void;
    toggleIsUserAgree: () => void;
    closeAmountTooltip: () => void;
    getAddress: () => void;
    getDepositAmount: () => void;
    whitelist: () => void;
    copy: (text: string, fieldName: string) => void;
    toggleQr: () => void;
    calculateQrTop: () => void;
    toggleDetails: () => void;
};

type Computed = {
    mainHeaderText: string;
    currentUser: InternalUserResponse | undefined;
    isKycVerified: boolean;
    blockchains: string[];
    blockchainsNativeAssetSymbols: string[];
    currenciesList: string[];
    depositMinSize: number;
    activeAccountId: string;
    qrValue: string;
    confirmationInfoText: string;
    firstStepInfoText: string;
    secondStepInfoText: string;
};

export default Vue.extend<Data, Methods, Computed>({
    components: {
        QrCode,
        Icon,
        Checkbox,
        AssetValue,
        MoneyInput,
        AssetsBlockchainsDropdown,
        Button,
        TextInput,
    },
    data() {
        return {
            step: 1,
            previousRoute: '',
            showAmountTooltip: true,
            showWhitelistingDetails: true,
            showQr: false,
            formData: {
                name: '',
                networkIndex: 0,
                address: '',
                amount: 0,
                isUserAgree: false,
            },
            errors: {
                name: false,
                address: false,
                amount: false,
                isUserAgree: false,
            },
            depositData: {
                address: '',
                memo: '',
                id: '',
                totalAmount: 0,
            },
        };
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
        }),
        confirmationInfoText() {
            if (this.currentUser?.type === 'CORPORATE') {
                return `
                    <span>
                        You're adding a new crypto wallet to your whitelist.
                        <br />
                        All crypto deposits must originate only from wallets that belong
                        <br />
                        to your company and have been whitelisted.
                        <br />
                        Transactions from unlisted wallets will be rejected.
                    </span>
                `;
            }
            return `
                <span>
                    You're adding a new crypto wallet to your whitelist.
                    <br />
                    All crypto deposits must originate only from wallets that belong
                    <br />
                    personally to you and have been whitelisted.
                    <br />
                    Transactions from unlisted wallets will be rejected.
                </span>
            `;
        },
        firstStepInfoText() {
            if (this.currentUser?.type === 'CORPORATE') {
                return `
                    <span>
                        Create a clear and unique name for the wallet to easily identify it within your company, e.g., "BTC Corporate Main Wallet" or "ETH Treasury Wallet".
                    </span>
                `;
            }
            return `
                <span>
                    Create a clear and unique name for your wallet so it's easy for you to identify, e.g., "My BTC Savings Wallet" or "Personal ETH Wallet".
                </span>
            `;
        },
        secondStepInfoText() {
            return `
                <span>
                    Double-check your wallet address and network carefully. Using the wrong network or incorrect address will lead to permanent loss of your funds.
                </span>
            `;
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        mainHeaderText() {
            switch (this.step) {
                case 1: {
                    return 'new whitelisted wallet name';
                }
                case 2: {
                    return 'new whitelisted wallet settings';
                }
                case 3: {
                    return 'deposit amount';
                }
                case 4: {
                    return 'wallet details confirmation';
                }
                case 5: {
                    return 'deposit to whitelist your wallet';
                }
                default: {
                    return '';
                }
            }
        },
        blockchains() {
            return (this.$route.query.allBlockchains as any[]).reduce((accum, current) => {
                if (
                    current.name
                    && this.currenciesList.some((c) => c === current.nativeAssetSymbol)
                ) {
                    accum.push(current.name);
                }
                return accum;
            }, []);
        },
        blockchainsNativeAssetSymbols() {
            return (this.$route.query.allBlockchains as any[]).reduce((accum, current) => {
                if (
                    current.nativeAssetSymbol
                    && this.currenciesList.some((c) => c === current.nativeAssetSymbol)
                ) {
                    accum.push(current.nativeAssetSymbol);
                }
                return accum;
            }, []);
        },
        currenciesList() {
            return (this.$route.query.assets as any[]).reduce((accum, current) => {
                if (current.symbol) {
                    accum.push(current.symbol);
                }
                return accum;
            }, []);
        },
        depositMinSize() {
            const details = (this.$route.query.assets as any[])
                .find((a) => a.symbol === this.blockchainsNativeAssetSymbols[this.formData.networkIndex])?.transferDetails;
            const detail = details?.find(({ source, destination, blockchainName }) => (
                source === 'Single Broker'
                && destination === 'Single Broker'
                && blockchainName === this.blockchains[this.formData.networkIndex]
            ));
            return detail?.transferMinSize ?? 0;
        },
        qrValue() {
            return formCryptoQr({
                network: this.blockchains[this.formData.networkIndex],
                address: this.depositData.address,
                amount: this.depositData.totalAmount,
            });
        },
    },
    methods: {
        toggleDetails() {
            this.showWhitelistingDetails = !this.showWhitelistingDetails;
            if (this.showQr) {
                setTimeout(() => {
                    this.calculateQrTop();
                }, 300);
            }
        },
        toggleQr() {
            this.calculateQrTop();
            this.showQr = !this.showQr;
        },
        calculateQrTop() {
            const topMargin = 72;
            const goBackHeight = 14;
            const goBackMargin = 15;
            const headerHeight = 54;

            const rects = (this.$refs.qrButton as any).getBoundingClientRect();
            this.$nextTick(() => {
                (this.$refs.qr as any).$el.style.top = `
                    ${rects.y
                - topMargin
                - goBackMargin
                - goBackHeight
                - headerHeight
                + (rects.height / 2)}px
                `;
            });
        },
        copy(text, fieldName) {
            if (text && fieldName) {
                copyTextWithPermissions(String(text), this.$store.dispatch, fieldName);
            }
        },
        async goBack() {
            if (this.previousRoute) {
                await this.$router.push(this.previousRoute).catch(() => { /* navigation error */ });
                return;
            }
            this.$router.go(-1);
        },
        async cancel() {
            if (this.step === 1) {
                this.goBack();
                return;
            }
            if (this.step === 5) {
                try {
                    this.$store.commit(SET_LOADING_ON(undefined));
                    await WalletsApi.cancelDeposit(new CancelDepositRequestData({
                        transferId: this.depositData.id,
                    }));
                    await this.$store.dispatch('Notificator/showSuccessNotification', 'Deposit has been successfully canceled');
                    await this.goBack();
                } catch (error) {
                    if (error instanceof ApiError) {
                        await this.$store.dispatch('Notificator/showErrorNotification', error?.data?.message ?? 'Something went wrong');
                    } else {
                        await this.$store.dispatch('Notificator/showErrorNotification', 'Something went wrong');
                    }
                } finally {
                    this.$store.commit(SET_LOADING_OFF(undefined));
                }
                return;
            }
            this.step -= 1;
        },
        async nextStep() {
            switch (this.step) {
                case 1: {
                    this.formData.name = this.formData.name.trim();
                    if (this.formData.name.length >= 3 && this.formData.name.length <= 20) {
                        this.step += 1;
                    } else {
                        this.errors.name = true;
                    }
                    break;
                }
                case 2: {
                    this.formData.address = this.formData.address.trim();
                    if (this.formData.address.length > 0) {
                        this.step += 1;
                    } else {
                        this.errors.address = true;
                    }
                    break;
                }
                case 3: {
                    if (this.formData.amount >= this.depositMinSize) {
                        this.step += 1;
                    } else {
                        this.errors.amount = true;
                        setTimeout(() => {
                            this.showAmountTooltip = true;
                        }, 0);
                    }
                    break;
                }
                case 4: {
                    if (this.formData.isUserAgree) {
                        await this.whitelist();
                    } else {
                        this.errors.isUserAgree = true;
                    }
                    break;
                }
                case 5: {
                    await this.goBack();
                    break;
                }
                default: {
                    break;
                }
            }
        },
        presetBlockchain(blockchain) {
            const index = this.blockchains.findIndex((b) => b === blockchain);
            if (index !== -1) {
                this.formData.networkIndex = index;
            }
        },
        setName(value) {
            this.formData.name = value;
            this.errors.name = false;
        },
        setAddress(value) {
            this.formData.address = value;
            this.errors.address = false;
        },
        setAmount(value) {
            this.formData.amount = value;
            this.errors.amount = false;
        },
        setNetworkIndex(index) {
            this.formData.networkIndex = index;
        },
        toggleIsUserAgree() {
            this.formData.isUserAgree = !this.formData.isUserAgree;
            this.errors.isUserAgree = false;
        },
        closeAmountTooltip() {
            this.showAmountTooltip = false;
        },
        async getAddress() {
            try {
                const { data: res } = await WalletsApi.getAddress(new WalletAddressRequestData({
                    accountId: this.activeAccountId,
                    assetSymbol: this.blockchainsNativeAssetSymbols[this.formData.networkIndex],
                    blockchainName: this.blockchains[this.formData.networkIndex],
                }));
                if (res instanceof WalletAddressResponseData) {
                    this.depositData.address = res.address;
                    this.depositData.memo = res.memo ?? '';
                } else {
                    this.depositData.address = '';
                    this.depositData.memo = '';
                }
            } catch (error) {
                this.depositData.address = '';
                this.depositData.memo = '';
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error?.data?.message ?? 'Something went wrong');
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Something went wrong');
                }
            }
        },
        async getDepositAmount() {
            try {
                const { data: res } = await WalletsApi.createDeposit(new CreateDepositRequestData({
                    accountId: this.activeAccountId,
                    address: this.formData.address,
                    addressAlias: this.formData.name,
                    amount: this.formData.amount,
                    assetSymbol: this.blockchainsNativeAssetSymbols[this.formData.networkIndex],
                    blockchainName: this.blockchains[this.formData.networkIndex],
                }));
                const { transferId } = res;
                const { data: transfer } = await WalletsApi.privateGetTransfer(new TransferRequest({
                    id: transferId,
                }));
                this.depositData.totalAmount = transfer.quantity;
                this.depositData.id = transferId;
            } catch (error) {
                this.depositData.totalAmount = 0;
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error?.data?.message ?? 'Something went wrong');
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Something went wrong');
                }
            }
        },
        async whitelist() {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                await this.getAddress();
                await this.getDepositAmount();
                if (
                    this.depositData.address !== ''
                    && this.depositData.totalAmount !== 0
                ) {
                    this.step += 1;
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
    },
    async mounted() {
        const {
            previousRoute,
            selectedBlockchain,
        } = this.$route.query;

        if (typeof previousRoute === 'string') {
            this.previousRoute = previousRoute;
        }
        if (typeof selectedBlockchain === 'string') {
            this.presetBlockchain(selectedBlockchain);
        }

        document.addEventListener('click', this.closeAmountTooltip);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeAmountTooltip);
    },
});
